import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Box,
  Chip,
  CircularProgress,
  Alert,
  Divider,
  Paper,
  Container,
  Grid,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLink,
  faExternalLinkAlt,
  faCompassDrafting,
} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import SetDocumentMeta from "../common/SetDocumentMeta";
import parse from "html-react-parser";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;
const IMAGE_BASE_URL =
  process.env.NODE_ENV === "production"
    ? ""
    : "http://djdev-lpim-backend.rfwel.com:8000/";
const technicalDetailUrlBase = "https://wdi.rfwel.com/wireless-standards/";

const WirelessStdDetail = () => {
  const { slug } = useParams();
  const [standard, setStandard] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // @TODO - make dynamic based on the standard
  const pageTitle = "Wireless Standard Detail | Telecom Law Help";
  const pageMetaDescription = "";
  const pageMetaKeywords = "";

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  useEffect(() => {
    const fetchStandardDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-telecom/${slug}/standard-detail`
        );
        setStandard(response.data);
      } catch (err) {
        setError("Failed to fetch standard detail.");
        console.error("Error fetching standard detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStandardDetail();
  }, [slug]);

  if (isLoading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  if (error)
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>{" "}
        </Grid>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to={"/wireless-standards"}
          sx={{ mb: 2, mt: 2, textTransform: "none" }}
        >
          Back to Standards Search
        </Button>
      </Container>
    );
  if (!standard)
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="warning">No standard data found.</Alert>{" "}
        </Grid>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to={"/wireless-standards"}
          sx={{ mb: 2, mt: 2, textTransform: "none" }}
        >
          Back to Standards Search
        </Button>
      </Container>
    );

  return (
    <Container className="link-no-decoration">
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            color="secondary"
            variant="outlined"
            component={RouterLink}
            to={"/wireless-standards"}
            sx={{ mb: 2, textTransform: "none" }}
          >
            Back to Standards Search
          </Button>

          <Box mb={3}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ display: "flex", alignItems: "center" }}
            >
              {standard.standard_logo && (
                <img
                  src={`${IMAGE_BASE_URL}${standard.standard_logo}`}
                  alt={`${standard.standard} logo`}
                  style={{ height: 64, width: "auto", marginRight: 8 }}
                />
              )}
              {standard.standard}
            </Typography>
            {standard.standard_org && (
              <Chip
                label={standard.standard_org}
                component={RouterLink}
                to={`/wireless-standards-org/${standard.standard_org_slug}`}
                clickable
                sx={{
                  backgroundColor: "#007bff",
                  color: "white",
                  marginBottom: 2,
                }}
              />
            )}

            {standard.link && (
              <Typography variant="body1" sx={{ mb: 2 }}>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginRight: 8 }}
                />
                <a
                  href={standard.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`View ${standard.standard} Standard`}
                </a>
              </Typography>
            )}

            {standard.standard_blurb && (
              <Typography variant="body1" sx={{ mb: 2 }}>
                {standard.standard_blurb}
              </Typography>
            )}

            {/* Summary Section */}
            {standard.refs_for_standard?.filter(
              (ref) => ref.ref_type === "Legal-Only" && ref.is_summary_table
            ).length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>
                  Legal Summary
                </Typography>
                {standard.refs_for_standard
                  .filter(
                    (ref) =>
                      ref.ref_type === "Legal-Only" && ref.is_summary_table
                  )
                  .map((ref, index) => (
                    <React.Fragment key={index}>
                      {ref.ref_discussion_title && (
                        <Typography
                          key={`${index}-title`}
                          variant="body1"
                          sx={{ mt: 1 }}
                        >
                          <strong>{ref.ref_discussion_title}</strong>{" "}
                        </Typography>
                      )}
                      {ref.ref_discussion && (
                        <Box
                          key={`${index}-discussion`}
                          color="textSecondary"
                          // sx={{ ml: 4 }}
                        >
                          {parse(ref.ref_discussion)}
                        </Box>
                      )}

                      {ref.ref_link_label && ref.ref_link && (
                        <Typography
                          key={`${index}-link`}
                          variant="body1"
                          sx={{ ml: 4, mb: 2 }}
                        >
                          <FontAwesomeIcon
                            key={`${index}-icon`}
                            icon={faLink}
                            style={{ marginRight: 8 }}
                          />
                          <a
                            href={ref.ref_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {ref.ref_link_label}
                          </a>
                        </Typography>
                      )}
                    </React.Fragment>
                  ))}
              </Grid>
            )}

            {standard.standard_description && (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: standard.standard_description,
                }}
              />
            )}

            <Divider sx={{ my: 3 }} />
            <Typography variant="h4" gutterBottom>
              Technical Details
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, ml: 3 }}>
              <FontAwesomeIcon
                icon={faCompassDrafting}
                style={{ marginRight: 8 }}
              />
              <a
                href={`${technicalDetailUrlBase}${standard.slug}`}
                target="_blank"
              >
                Explore {standard.standard} Tech Details
              </a>{" "}
              <Typography variant="body2" color="textSecondary" sx={{ ml: 3 }}>
                (Source: Rfwel Engr Wireless Device Research)
              </Typography>
            </Typography>

            {standard.features.length > 0 && (
              <>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h4" gutterBottom>
                  Features
                </Typography>
                {standard.features.map((feature, index) => (
                  <Box key={index} mb={2}>
                    <Typography>
                      <strong>{feature.feature_attribute}:</strong>{" "}
                      {feature.feature_value}
                    </Typography>
                  </Box>
                ))}
              </>
            )}

            {/* Standard Revisions */}
            {standard.revisions.length > 0 && (
              <>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h4" gutterBottom>
                  Revisions
                </Typography>
                {standard.revisions.map((rev, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#e8e8e8", // Alternating background colors
                      p: 2,
                      borderRadius: 1,
                      mb: 2,
                      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Soft shadow
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "#333" }}
                    >
                      {rev.rev}
                    </Typography>
                    {rev.rev_date && (
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ color: "#666", mt: 1 }}
                      >
                        {rev.rev_date}
                      </Typography>
                    )}
                    {rev.rev_url && (
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        <FontAwesomeIcon
                          icon={faLink}
                          style={{ marginRight: 8, color: "#007bff" }}
                        />
                        <a
                          href={rev.rev_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "#007bff",
                            "&:hover": { color: "#0056b3" },
                          }}
                        >
                          {rev.rev_url}
                        </a>
                      </Typography>
                    )}
                    {rev.rev_description && (
                      <Typography
                        sx={{ mt: 2, color: "textSecondary" }}
                        dangerouslySetInnerHTML={{
                          __html: rev.rev_description,
                        }}
                      />
                    )}
                  </Box>
                ))}
              </>
            )}

            {/* Standard Refs Section */}
            {standard.refs_for_standard?.filter(
              (ref) =>
                !ref.is_summary_table &&
                (ref.ref_type === "Legal-Only" || ref.ref_type === "General")
            ).length > 0 && (
              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>
                  References
                </Typography>
                {standard.refs_for_standard
                  .filter(
                    (ref) =>
                      !ref.is_summary_table &&
                      (ref.ref_type === "Legal-Only" ||
                        ref.ref_type === "General")
                  )
                  .map((ref, index) => (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "#f9f9f9" : "#e8e8e8", // Alternating background colors
                        p: 2,
                        borderRadius: 1,
                        mb: 2,
                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Soft shadow
                      }}
                    >
                      {ref.ref_discussion_title && (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            color: "#333",
                            mb: 1,
                          }}
                        >
                          {ref.ref_discussion_title}
                        </Typography>
                      )}
                      {ref.ref_discussion && (
                        <Box color="textSecondary" sx={{ ml: 2, mb: 1 }}>
                          {parse(ref.ref_discussion)}
                        </Box>
                      )}
                      {ref.ref_link_label && ref.ref_link && (
                        <Typography variant="body1" sx={{ ml: 2 }}>
                          <FontAwesomeIcon
                            icon={faLink}
                            style={{ marginRight: 8, color: "#007bff" }}
                          />
                          <a
                            href={ref.ref_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "#007bff",
                              // fontWeight: "bold",
                              "&:hover": { color: "#0056b3" },
                            }}
                          >
                            {ref.ref_link_label}
                          </a>
                        </Typography>
                      )}
                    </Box>
                  ))}
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WirelessStdDetail;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Container, Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReusableTitle from "../common/ReusableTitle";
import { faTowerBroadcast } from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../common/SetDocumentMeta";
import BodyContentBlock from "../common/BodyContentBlock";

const KthServicesWireless = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;
  SetDocumentMeta({
    title:
      "Wireless Telecom Lawyer | FCC Compliance, Spectrum Licensing & Infrastructure",
    description:
      "Specialized legal services for telecommunications and wireless technologies. Expertise in FCC compliance, spectrum licensing, wireless deployment, DAS, and more. Contact us for professional guidance.",
    keywords:
      "wireless telecom law, spectrum licensing, FCC compliance, FCC equipment authorization, 5G lawyer, DAS attorney, Neutral Host, infrastructure transactions, wireless backhaul, LTE Offload, \
      5G deployment, small cell networks, telecom legal services",
  });

  const bodyContent = [
    {
      contentId: "fcc-compliance",
      image: "fcc-seal-535x427.png",
      title: "FCC Compliance and Equipment Authorization",
      text: "Our team offers end-to-end legal support for FCC equipment authorization, spectrum allocation, antenna structure registration, and compliance. We assist with application preparation, submission, \
      and management of ongoing compliance with rules and regulations. We also provide representation in FCC enforcement actions and audits.",
      imagePosition: "right",
      link: "/wireless-legal-compliance?type=regulation&jurisdiction=fcc",
      linkExternal: false,
      newsLink: "/news-insights?practice=wireless",
      newsLinkExternal: false,
    },
    {
      contentId: "infrastructure",
      image: "kth-head-4-wireless.png",
      title: "Wireless Infrastructure",
      text: "We provide comprehensive legal support for 5G and wireless infrastructure deployment, including DAS, small cells, and Neutral Host networks. \
      Our services cover contract drafting and negotiation with landlords, municipalities, and service providers, as well as guidance on zoning laws and regulatory compliance.",
      imagePosition: "left",
      newsLink: "/news-insights?practice=wireless",
      newsLinkExternal: false,
    },
    {
      contentId: "spectrum-licensing",
      image: "antennas-534x427.png",
      title: "Spectrum Licensing and Transactions",
      text: "We guide clients through complex spectrum licensing processes. \
      Our expertise covers FCC spectrum policies, license transfers, and due diligence for spectrum-related mergers and acquisitions. \
      Some of the Radio Services we assist with include: \
      <ul> \
      <li> <b><a href='/wireless-legal-compliance/part-101-fixed-microwave-services'>Part 101:</a></b> Fixed Microwave Service - microwave radio systems for long distances communication. </li> \
      <li> <b><a href='/wireless-legal-compliance/part-17-antenna-structures'>Part 17:</a></b> Antenna Structure Registration (ASR) - registering, marking, and lighting antenna structures to ensure compliance with aviation safety regulations. </li> \
      <li> <b><a href='/wireless-legal-compliance/part-96-citizens-broadband-radio-service'>Part 96:</a></b> Covers Citizens Broadband Radio Service (CBRS) - shared access to the 3.5 GHz band. </li> \
      <li> <b><a href='/wireless-legal-compliance/part-90-private-land-mobile-radio-services'>Part 90:</a></b> Private Land Mobile Radio Service (PLMRS)) - private, non-broadcast radio use by businesses, public safety, and local governments. </li> \
      <li> <b><a href='/wireless-legal-compliance/part-87-aviation-services'>Part 87:</a></b> Aviation Service - radio communication systems used in aviation. </li> \
      <li> <b><a href='/wireless-legal-compliance/part-15-radio-frequency-devices'>Part 15:</a></b> Unlicensed RF Devices like Wi-Fi routers, Bluetooth, and other wireless devices. </li> \
      <li> <b><a href='/wireless-legal-compliance/part-97-amateur-radio-service'>Part 97:</a></b> Amateur Radio Service (Hame Radio) - non-commercial radio communications by licensed amateur radio operators. <i>Roque Thuo serves as volunteer counsel for the ARRL.</i> </li> \
      <li> <b><a href='/wireless-legal-compliance/XXX'>Part 5:</a></b> Experimental Radio Service -  licensing for the development, testing, and experimentation of radio technologies and services that may not yet be authorized for general commercial use. </li> \
      </ul> \
      ",
      imagePosition: "right",
      link: "/wireless-legal-compliance?type=regulation&jurisdiction=fcc",
      linkExternal: false,
      newsLink: "/news-insights?category=wireless-telecom",
      newsLinkExternal: false,
    },
    {
      contentId: "regulatory-advocacy",
      image: "wireless-regs.png",
      title: "Regulatory Advocacy and Policy",
      text: "Kama Thuo, PLLC represents clients before the FCC and state regulatory bodies. We provide strategic advocacy on policy matters affecting the wireless industry, \
      including rulemaking proceedings, waiver requests, and interpretation of regulations.",
      imagePosition: "left",
      newsLink: "/news-insights?category=wireless-telecom",
      newsLinkExternal: false,
    },
    {
      contentId: "iot-and-emerging-tech",
      image: "kth_ai_iot.png",
      title: "IoT and Emerging Technologies",
      text: "Stay ahead of the curve with our legal guidance on emerging wireless technologies, including private networks, IoT, V2X, and beyond. \
      Our team provides guidance on regulatory compliance and contractual matters specific to IoT and next-generation wireless solutions.",
      imagePosition: "right",
      newsLink: "/news-insights?practice=wireless",
      newsLinkExternal: false,
    },

    //@@@TODO!!!!!!!!!!
    // Add Emergency Radio Compliance
    // Consider other services that RFW team can provide w/ little/no lawyer supervision
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>
          <ReusableTitle
            title="Expert Legal Services for the Wireless Industry"
            isMainHeading={true}
            siteBranding={baseConfig.siteBranding}
            icon={{ faIcon: faTowerBroadcast }}
          />
        </Container>
        <Container>
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/news-insights?practice=wireless"
          >
            News & Insights: WIRELESS
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12}>
        {bodyContent.map((content, index) => (
          <BodyContentBlock
            key={index}
            content={content}
            baseConfig={baseConfig}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default KthServicesWireless;

import React from "react";

//--------------------------------------------------------------------------------------
// TEMPORARY DEVELOPMENT ISH -> MOVE OUT OF HERE
//--------------------------------------------------------------------------------------

// TEMP styling -> Move to CSS
export const RFW_HORIZDOT = (
  //<hr style={{ borderBottom: "dotted 1px #000" }}></hr>
  <hr
    style={{
      borderTop: "2px dotted #999",
      marginTop: "0.9091em",
      paddingTop: "0.9091em",
    }}
  ></hr>
);

import React, { createContext, useState, useEffect, useContext } from "react";

const WorksheetContext = createContext();

export const useWorksheet = () => useContext(WorksheetContext);

export const WorksheetProvider = ({ children }) => {
  const [worksheet, setWorksheet] = useState({
    patents: [],
    devices: [],
    licensing: [],
  });

  useEffect(() => {
    const storedWorksheet = localStorage.getItem("worksheet");
    if (storedWorksheet) {
      setWorksheet(JSON.parse(storedWorksheet));
    }
  }, []);

  // IDKeys for different workesheets
  const getIdKeyByType = (type) => {
    if (type === "patents") return "pn";
    if (type === "devices") return "devid";
    if (["patent-enforcement", "ai-compliance", "ai-eula"].includes(type))
      return "wizid";
    if (type === "licensing") return "segment";
    return "pn"; // Default or fallback if no matching type
  };

  //-------------------------------------------------------------------------------------------------
  // Field Mapping - Only these fields are saved
  //-------------------------------------------------------------------------------------------------
  const fieldsMapping = {
    patents: ["publication_id", "id", "pn", "title", "publication_date"],
    devices: [
      "devid",
      "mpn",
      "title",
      "brand_name",
      "category_name",
      "standards",
    ],
    "patent-enforcement": ["wizid", "wizall"],
    "ai-compliance": ["wizid", "wizall"],
    "ai-eula": ["wizid", "wizall"],
    licensing: ["segment", "vertical", "applications", "companies"],
  };

  //-------------------------------------------------------------------------------------------------
  // Helper function to standardize patent number key
  //-------------------------------------------------------------------------------------------------

  const standardizePatentKey = (patent, source) => {
    const keyAliases = ["publication_id", "id", "pn"]; // Extend this list as needed
    for (let key of keyAliases) {
      if (patent.hasOwnProperty(key)) {
        return {
          ...patent,
          pn: patent[key],
          source: source || patent.source || "default source", // Use passed source or fallback to existing source
        };
      }
    }
    return patent; // Return original if no known key is found
  };

  //-------------------------------------------------------------------------------------------------
  // Function to Add item to Worksheet
  //-------------------------------------------------------------------------------------------------
  // Only saves the fields in the fieldMapping
  const addToWorksheet = (item, type, source = "default source") => {
    const currentItems = worksheet[type] || []; // Safeguard against undefined with a fallback to an empty array
    const fieldsToKeep = fieldsMapping[type];

    // Initialize filteredItem
    let filteredItem = {};

    // Filter the item to only include the specified fields
    fieldsToKeep.forEach((field) => {
      if (item.hasOwnProperty(field)) {
        filteredItem[field] = item[field];
      }
    });

    // Add source to the item
    filteredItem.source = source;

    // Only standardize patent items; check if it's the right type for processing
    if (type === "patents") {
      filteredItem = standardizePatentKey(filteredItem, source);
    }

    const updatedWorksheet = {
      ...worksheet,
      [type]: [...currentItems, filteredItem], // Add filtered item
    };

    setWorksheet(updatedWorksheet);
    localStorage.setItem("worksheet", JSON.stringify(updatedWorksheet));
  };

  //-------------------------------------------------------------------------------------------------
  // Function to Remove item from Worksheet
  //-------------------------------------------------------------------------------------------------
  const removeFromWorksheet = (id, type) => {
    // Determine the identifier key based on the type
    // const idKey =
    //   type === "patents"
    //     ? "pn"
    //     : type === "devices"
    //     ? "devid"
    //     : ["patent-enforcement", "ai-compliance", "ai-eula"].includes(type)
    //     ? "wizid"
    //     : type === "licensing"
    //     ? "segment"
    //     : "pn"; // Default or fallback if no matching type
    const idKey = getIdKeyByType(type);

    // Filter out the item with the given identifier
    const updatedWorksheet = {
      ...worksheet,
      [type]: worksheet[type].filter((item) => item[idKey] !== id),
    };

    setWorksheet(updatedWorksheet);
    localStorage.setItem("worksheet", JSON.stringify(updatedWorksheet));
  };

  //-------------------------------------------------------------------------------------------------
  // Function to Replace item in Worksheet
  //-------------------------------------------------------------------------------------------------
  // Function to Replace item in Worksheet
  const replaceInWorksheet = (item, type, source = "default source") => {
    // Get idKey
    const idKey = getIdKeyByType(type);

    // Filter out the existing item with the same id
    const updatedItems = worksheet[type].filter(
      (existingItem) => existingItem[idKey] !== item[idKey]
    );

    // Add the new item
    const fieldsToKeep = fieldsMapping[type];
    let filteredItem = {};
    fieldsToKeep.forEach((field) => {
      if (item.hasOwnProperty(field)) {
        filteredItem[field] = item[field];
      }
    });
    filteredItem.source = source;

    const updatedWorksheet = {
      ...worksheet,
      [type]: [...updatedItems, filteredItem],
    };

    setWorksheet(updatedWorksheet);
    localStorage.setItem("worksheet", JSON.stringify(updatedWorksheet));
  };
  //-------------------------------------------------------------------------------------------------
  // Return
  //-------------------------------------------------------------------------------------------------
  return (
    <WorksheetContext.Provider
      value={{
        worksheet,
        addToWorksheet,
        removeFromWorksheet,
        replaceInWorksheet,
      }}
    >
      {children}
    </WorksheetContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Container,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Alert,
  Button,
  Grid,
  useMediaQuery,
  InputAdornment,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToolbox,
  faPrintMagnifyingGlass,
  faFileCheck,
  faGearComplexCode,
  faNewspaper,
  faPresentationScreen,
  faMessageBot,
  faBrainCircuit,
  faFileCertificate,
  faChevronLeft,
  faChevronRight,
  faSearchMinus,
  faSearchPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CloseIcon from "@mui/icons-material/Close";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import parse from "html-react-parser";
import Pagination from "../common/Pagination";
import * as CONFIG from "./utils/kthGlobalLabelsVars";
import SetDocumentMeta from "../common/SetDocumentMeta";

const SHOW_CATEGORIES = 6;
const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;
const ITEMS_PER_PAGE = process.env.NODE_ENV === "production" ? 10 : 5;
const INITIAL_ZOOM_SMALL_SCREEN = 0.6; // initial zoom out
const INITIAL_DRAGOFFSET_SMALL_SCREEN = { x: -350, y: -150 }; // adjust for initial zoom out
const IMAGE_BASE_URL =
  process.env.NODE_ENV === "production"
    ? ""
    : "http://djdev-lpim-backend.rfwel.com:8000/";

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const offset = 20;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  };
  const date = new Date(dateString + "T00:00:00Z");
  return date.toLocaleDateString("en-US", options);
};

const AiResources = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;
  SetDocumentMeta({
    title: "Patent AI Resources | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const [skipInitialEffect, setSkipInitialEffect] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    resource_type: "",
    category: "",
    search: "",
  });
  const [sortOption, setSortOption] = useState("relevance");
  const [currentPage, setCurrentPage] = useState({});
  const [showImages, setShowImages] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentResourceImages, setCurrentResourceImages] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(1);

  // Update filters based on URL query parameters
  // useEffect(() => {
  //   fetchResources();
  // }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const resourceType = queryParams.get("type") || "";
    const category = queryParams.get("category") || "";
    const search = queryParams.get("search") || "";

    setFilters((prevFilters) => ({
      ...prevFilters,
      resource_type: resourceType,
      category: category,
      search: search,
    }));
    setSkipInitialEffect(false); // Allow subsequent effects to run
    fetchResources();
  }, [location.search]);

  // Apply filters to content
  useEffect(() => {
    if (skipInitialEffect) return; // Skip this effect during initialization
    applyFilters();
    updateURLWithFilters();
  }, [filters, resources, sortOption]);

  const fetchResources = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlKth}/kth-ai/resources`);
      setResources(response.data);
      setFilteredResources(response.data);

      const uniqueResourceTypes = Array.from(
        new Set(response.data.map((resource) => resource.resource_type.slug))
      ).map(
        (slug) =>
          response.data.find((resource) => resource.resource_type.slug === slug)
            .resource_type
      );

      const uniqueCategories = Array.from(
        new Set(response.data.map((resource) => resource.category.slug))
      ).map(
        (slug) =>
          response.data.find((resource) => resource.category.slug === slug)
            .category
      );

      setResourceTypes(uniqueResourceTypes);
      setCategories(uniqueCategories);
    } catch (error) {
      setError(
        "Failed to fetch resources. Please check your network and try again."
      );
      console.error("Failed to fetch resources:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    const { resource_type, category, search } = filters;
    let filtered = resources.filter((resource) => {
      const matchesResourceType =
        !resource_type ||
        (resource.resource_type &&
          resource.resource_type.slug === resource_type);
      const matchesCategory =
        !category || (resource.category && resource.category.slug === category);
      const matchesSearch =
        !search ||
        resource.title.toLowerCase().includes(search.toLowerCase()) ||
        resource.description.toLowerCase().includes(search.toLowerCase());
      return matchesResourceType && matchesCategory && matchesSearch;
    });

    const getDate = (resource) => new Date(resource.release_date || "");

    const importanceOrder = {
      Featured: 1,
      Important: 2,
      Standard: 3,
      Low: 4,
    };

    if (sortOption === "relevance") {
      filtered = filtered.sort((a, b) => {
        const importanceA = importanceOrder[a.importance] || 4;
        const importanceB = importanceOrder[b.importance] || 4;

        if (importanceA !== importanceB) {
          return importanceA - importanceB;
        }

        const dateA = getDate(a);
        const dateB = getDate(b);

        return dateB - dateA;
      });
    } else if (sortOption === "date") {
      filtered = filtered.sort((a, b) => {
        const dateA = getDate(a);
        const dateB = getDate(b);

        if (dateA !== dateB) {
          return dateB - dateA;
        }

        const importanceA = importanceOrder[a.importance] || 4;
        const importanceB = importanceOrder[b.importance] || 4;

        return importanceA - importanceB;
      });
    }

    setFilteredResources(filtered);
    setCurrentPage({});
    setShowImages({});
  };

  const updateURLWithFilters = () => {
    const queryParams = new URLSearchParams();

    if (filters.resource_type) queryParams.set("type", filters.resource_type);
    if (filters.category) queryParams.set("category", filters.category);
    if (filters.search) queryParams.set("search", filters.search);

    const newUrl = queryParams.toString()
      ? `/patent-ai-resources?${queryParams.toString()}`
      : "/patent-ai-resources";

    navigate(newUrl, { replace: true });
  };

  const clearFilters = () => {
    setFilters({
      resource_type: "",
      category: "",
      search: "",
    });
  };

  const handleSearchChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: event.target.value,
    }));
  };

  const handleFilterChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    setCurrentPage({});
  };

  const handlePageChange = (resourceType, page) => {
    setCurrentPage({
      ...currentPage,
      [resourceType]: page,
    });
  };

  const groupedResources = filteredResources.reduce((acc, resource) => {
    const type = resource.resource_type.slug;
    if (!acc[type]) {
      acc[type] = {
        name: resource.resource_type.name,
        items: [],
      };
    }
    acc[type].items.push(resource);
    return acc;
  }, {});

  const resourceIcons = {
    "ai-news-insights": faBrainCircuit,
    "patent-news-insights": faFileCertificate,
    "ai-patent-tools": faToolbox,
    "research-papers": faPrintMagnifyingGlass,
    "white-papers": faFileCheck,
    "genai-chat-bots": faMessageBot,
  };

  const toggleShowImages = (id) => {
    setShowImages((prevShowImages) => ({
      ...prevShowImages,
      [id]: !prevShowImages[id],
    }));
  };

  const handleImageClick = (imageUrl, index, images) => {
    setSelectedImage(imageUrl);
    setSelectedImageIndex(index);
    setCurrentResourceImages(images);
    setZoomLevel(isSmallScreen ? INITIAL_ZOOM_SMALL_SCREEN : 1); // Set initial zoom level based on screen size
    setDragOffset(
      isSmallScreen ? INITIAL_DRAGOFFSET_SMALL_SCREEN : { x: 0, y: 0 }
    ); // Adjust drag offset to account for initial zoom
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % currentResourceImages.length;
      setSelectedImage(
        `${IMAGE_BASE_URL}${currentResourceImages[newIndex].image}`
      );
      return newIndex;
    });
  };

  const handlePreviousImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) => {
      const newIndex =
        (prevIndex - 1 + currentResourceImages.length) %
        currentResourceImages.length;
      setSelectedImage(
        `${IMAGE_BASE_URL}${currentResourceImages[newIndex].image}`
      );
      return newIndex;
    });
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleDragStart = (e) => {
    setDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleDrag = (e) => {
    if (dragging) {
      setDragOffset((prevOffset) => ({
        x: prevOffset.x + e.clientX - dragStart.x,
        y: prevOffset.y + e.clientY - dragStart.y,
      }));
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => prevZoom - 0.1);
  };

  const resetZoom = () => {
    setZoomLevel(isSmallScreen ? INITIAL_ZOOM_SMALL_SCREEN : 1); // Reset to initial zoom level based on screen size
    setDragOffset(
      isSmallScreen ? INITIAL_DRAGOFFSET_SMALL_SCREEN : { x: 0, y: 0 }
    ); // Reset image position
  };

  return (
    <Container className="link-no-decoration">
      <Typography variant="h4" sx={{ mt: 4 }}>
        Patent AI Resources
      </Typography>
      <Typography
        component="div"
        variant="body1"
        color="textSecondary"
        paragraph
        sx={{ lineHeight: 1.8 }}
      >
        This page provides handy resources for AI-based patent analysis
        including links and reviews of popular tools, research papers, white
        papers, pre-trained models, development tools (e.g., Jupyter Notebooks),
        etc.
      </Typography>

      <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Search keywords"
            value={filters.search}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            sx={{ marginBottom: { xs: 2, sm: 0 } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Resource Type</InputLabel>
            <Select
              name="resource_type"
              value={filters.resource_type}
              onChange={handleFilterChange}
              label="Resource Type"
            >
              <MenuItem value="">
                <em>All Resource Types</em>
              </MenuItem>
              {resourceTypes.map((type) => (
                <MenuItem key={type.slug} value={type.slug}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              options={categories}
              getOptionLabel={(option) => option.name}
              value={
                categories.find(
                  (category) => category.slug === filters.category
                ) || null
              }
              onChange={(event, newValue) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  category: newValue ? newValue.slug : "",
                }));
                setCurrentPage({});
              }}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.slug}>
                  {option.name}
                </li>
              )}
              ListboxProps={{
                style: {
                  maxHeight: SHOW_CATEGORIES * 48,
                  overflow: "auto",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="sort"
              name="sort"
              value={sortOption}
              onChange={handleSortChange}
            >
              <FormControlLabel
                value="relevance"
                control={<Radio />}
                label="Sort by Relevance"
              />
              <FormControlLabel
                value="date"
                control={<Radio />}
                label="Sort by Date"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Button
            onClick={clearFilters}
            startIcon={<SearchOffIcon />}
            variant="outlined"
            sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
          >
            Clear Filters
          </Button>
        </Grid>
      </Grid>

      {filters.search || filters.resource_type || filters.category ? (
        <Alert
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={clearFilters}>
              Show All
            </Button>
          }
        >
          Showing results for
          {filters.search && ` search keywords = "${filters.search}"`}
          {filters.resource_type && filters.search && " & "}
          {filters.resource_type && ` resource type = ${filters.resource_type}`}
          {(filters.resource_type || filters.search) &&
            filters.category &&
            " & "}
          {filters.category && ` category = ${filters.category}`}
        </Alert>
      ) : null}
      {CONFIG.RFW_HORIZDOT}
      {filteredResources.length > 0 && (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: "10px", marginBottom: "5px" }}
          >
            Click on link to jump to resource type:
          </Typography>
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 40 }}>
            {Object.keys(groupedResources).map((slug, index) => (
              <Typography variant="body1" style={{ marginRight: 8 }} key={slug}>
                <a
                  href={`#${slug}`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToElement(slug);
                  }}
                >
                  <FontAwesomeIcon
                    icon={resourceIcons[slug]}
                    style={{ marginRight: "7px" }}
                  />
                  {groupedResources[slug].name}
                </a>
                {index < Object.keys(groupedResources).length - 1 && " | "}
              </Typography>
            ))}
          </div>
        </>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : filteredResources.length === 0 ? (
        <Alert severity="warning" sx={{ mt: 4 }}>
          No results found for the selected filters.
        </Alert>
      ) : (
        Object.keys(groupedResources).map((slug) => {
          const resourceTypeItems = groupedResources[slug].items;
          const page = currentPage[slug] || 1;
          const paginatedItems = resourceTypeItems.slice(
            (page - 1) * ITEMS_PER_PAGE,
            page * ITEMS_PER_PAGE
          );

          return (
            <div key={slug}>
              <Typography variant="h5" id={slug} sx={{ mt: 6 }}>
                {groupedResources[slug].name} (
                {groupedResources[slug].items.length})
              </Typography>
              <hr />

              {paginatedItems.map((resource) => (
                <div key={resource.id}>
                  <Typography variant="body2" component="div">
                    {slug === "genai-chat-bots" ? (
                      // Use Link for internal navigation when slug is "genai-chat-bot"
                      <Typography
                        component={RouterLink}
                        to={`/ai-service/${resource.slug}`}
                        sx={{
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          color: "black",
                          "&:hover": {
                            color: tertColor,
                          },
                          transition: "color 0.3s",
                          textDecoration: "none", // Remove underline
                        }}
                      >
                        {resource.title}
                      </Typography>
                    ) : (
                      // Use <a> tag for external links
                      <Typography
                        component="a"
                        href={resource.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          color: "black",
                          "&:hover": {
                            color: tertColor,
                          },
                          transition: "color 0.3s",
                        }}
                      >
                        {resource.title}
                      </Typography>
                    )}
                    {resource.release_date && (
                      <>
                        <CalendarTodayIcon fontSize="small" sx={{ ml: 2 }} />{" "}
                        {formatDate(resource.release_date)}{" "}
                      </>
                    )}
                    {resource.resource_owner && (
                      <>
                        <BusinessIcon fontSize="small" sx={{ ml: 2 }} />{" "}
                        {resource.resource_owner}
                      </>
                    )}
                    {resource.authors && (
                      <>
                        <PersonIcon fontSize="small" sx={{ ml: 2 }} />{" "}
                        {resource.authors}
                      </>
                    )}
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ mt: 1, mb: 2 }}
                    >
                      {parse(resource.description)}
                    </Typography>
                    {resource.images && resource.images.length > 0 && (
                      <Button
                        startIcon={
                          showImages[resource.id] ? (
                            <KeyboardDoubleArrowUpIcon />
                          ) : (
                            <KeyboardDoubleArrowDownIcon />
                          )
                        }
                        onClick={() => toggleShowImages(resource.id)}
                        sx={{ mb: 2 }}
                      >
                        {showImages[resource.id]
                          ? "Hide Screenshots"
                          : "Show Screenshots"}
                      </Button>
                    )}
                    {showImages[resource.id] && (
                      <>
                        <Divider sx={{ my: 2 }} />
                        <Box
                          sx={{
                            display: "flex",
                            overflowX: "auto",
                            maxWidth: "100%",
                            mb: 4,
                          }}
                        >
                          {resource.images
                            .sort((a, b) => a.image_ordering - b.image_ordering)
                            .map((image, index) => (
                              <img
                                key={image.image}
                                src={`${IMAGE_BASE_URL}${image.image}`}
                                alt={image.image_alt}
                                style={{
                                  height: "150px",
                                  marginRight: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleImageClick(
                                    `${IMAGE_BASE_URL}${image.image}`,
                                    index,
                                    resource.images
                                  )
                                }
                              />
                            ))}
                        </Box>
                      </>
                    )}
                  </Typography>
                </div>
              ))}

              {resourceTypeItems.length > ITEMS_PER_PAGE && (
                <Box sx={{ mt: 5 }}>
                  <Pagination
                    itemsCount={resourceTypeItems.length}
                    pageSize={ITEMS_PER_PAGE}
                    currentPage={page}
                    onPageChange={(page) => handlePageChange(slug, page)}
                  />
                </Box>
              )}
            </div>
          );
        })
      )}

      {selectedImage && (
        <Dialog
          open={Boolean(selectedImage)}
          onClose={handleClose}
          maxWidth="lg"
          aria-labelledby="image-dialog-title"
          aria-describedby="image-dialog-description"
          onMouseDown={handleDragStart}
          onMouseMove={handleDrag}
          onMouseUp={handleDragEnd}
        >
          <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {currentResourceImages.length > 1 && isSmallScreen && (
              <>
                <IconButton
                  onClick={handlePreviousImage}
                  aria-label="previous"
                  sx={{ marginLeft: "auto" }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
                <IconButton onClick={handleNextImage} aria-label="next">
                  <FontAwesomeIcon icon={faChevronRight} />
                </IconButton>
              </>
            )}
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                pointerEvents: "auto",
                display: "flex",
                gap: 1,
              }}
            >
              <IconButton
                onClick={zoomOut}
                aria-label="zoom out"
                color="inherit"
              >
                <FontAwesomeIcon icon={faSearchMinus} />
              </IconButton>
              <IconButton
                onClick={resetZoom}
                aria-label="reset zoom"
                color="inherit"
              >
                <Typography variant="body1">Reset</Typography>
              </IconButton>
              <IconButton onClick={zoomIn} aria-label="zoom in" color="inherit">
                <FontAwesomeIcon icon={faSearchPlus} />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              cursor: dragging ? "grabbing" : "grab",
              overflow: isSmallScreen ? "auto" : "hidden",
              position: "relative",
              paddingBottom: isSmallScreen ? 0 : 5, // Add padding for large screens
            }}
          >
            <img
              src={selectedImage}
              alt="Selected Screenshot"
              style={{
                transform: `translate(${dragOffset.x}px, ${dragOffset.y}px) scale(${zoomLevel})`,
                transformOrigin: "center",
                width: isSmallScreen ? "auto" : "100%",
                height: isSmallScreen ? "auto" : "100%",
              }}
              onDragStart={(e) => e.preventDefault()}
            />
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ position: "absolute", bottom: 10, left: 10, right: 10 }}
            >
              {currentResourceImages[selectedImageIndex]?.description}
            </Typography>
            {!isSmallScreen && currentResourceImages.length > 1 && (
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  pointerEvents: "none",
                }}
              >
                <IconButton
                  onClick={handlePreviousImage}
                  aria-label="previous"
                  sx={{
                    pointerEvents: "auto",
                    position: "absolute",
                    left: 0,

                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
                <IconButton
                  onClick={handleNextImage}
                  aria-label="next"
                  sx={{
                    pointerEvents: "auto",
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </IconButton>
              </DialogActions>
            )}
          </DialogContent>
        </Dialog>
      )}

      <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" sx={{ mt: 6 }}>
            Additional Resources
          </Typography>
          <hr />
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/news-insights?practice=ai"
          >
            AI News & Insights
          </Button>
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/news-insights?practice=patents"
          >
            Patents News & Insights
          </Button>
        </Grid>
      </Grid>

      {error && <Typography color="error">{error}</Typography>}
    </Container>
  );
};

export default AiResources;
